
@import "../_custom.scss";

.highlighted {
  background-color: map-get($theme-colors, "highlight-light") !important;
}

.tree {
  overflow: auto;
}

.selectable:hover {
  cursor: pointer;
  background-color: map-get($theme-colors, "active") !important;
}
