
.artiq-color {
  color: #0a5487;
}
.icon-color {
  color: #ff7f00 !important;
}
.report-icon {
  min-width: 22px;
  text-align: center;
}
