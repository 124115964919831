
@import "../../../_custom.scss";

$tabHeight: 35px;

.tab {
  border-bottom: 0 !important;
  min-height: $tabHeight;
  height: $tabHeight;
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: $tabHeight;

  &:hover {
    background-color: map-get($theme-colors, "hover") !important;
  }

  &.active {
    border: 1px solid map-get($theme-colors, "highlight") !important;
    border-bottom: 0 !important;
    background-color: white !important;
  }

  &-new {
    @extend .tab;
    max-width: 40px;
  }
}
