
@import "../../../_custom.scss";

.filter-group {
  background-color: $input-bg;
}

.custom-hover:hover {
  background-color: inherit;
}
