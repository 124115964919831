
.dropdown-item-check-wrapper {
  min-width: 32px;
  justify-content: center;
  align-items: center;
}
.dropdown-selectable-item {
  padding: 0;
}
.dropdown-item-content {
  padding: 4px 8px 4px 0px;
}
.dropdown-group-title {
  padding: 4px 16px 4px 32px;
}
