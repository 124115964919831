
@import "../custom";

.highlighted {
  background-color: map-get($theme-colors, "highlight-light") !important;
}

.selection-list {
  list-style: none;
  padding: 0;

  li {
    &:hover {
      background-color: map-get($theme-colors, "hover") !important;
      cursor: pointer;
    }
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
