
@import "../../../_custom.scss";

.visit-label {
  font-size: 0.8rem;
}

.border-selected {
  border: 1px solid $input-border-color !important;
}

.visit-header {
  font-size: 14px;
  transition: background-color 0.2s linear;

  &:not(.bg-selected):hover {
    background-color: map-get($theme-colors, "hover") !important;
    cursor: pointer;
  }

  &.bg-selected {
    background-color: $input-bg !important;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
