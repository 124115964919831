
@import "../../../_custom.scss";

.comment-tab {
  border-right: 1px solid map-get($theme-colors, "light");
  cursor: pointer;

  &:hover {
    background-color: map-get($theme-colors, "hover") !important;
  }

  &.active {
    border-bottom: none !important;
    background-color: #ffffff !important;
  }
}

#embedded-viewer {
  display: block;
  border: none;
  padding: 0;
  margin: 0;
}
