
.scrollable {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2000;
  max-height: 20vh;
  overflow-y: auto;
}
.word-break {
  white-space: normal !important;
  word-break: break-word;
}
