// use this file to override bootstrap variables
$theme-colors: (
  "light": #ebebeb,
  "primary": #1e4388,
  "hover": #d3d3d3,
  "selected": #EFEFEF,
  "active": #ffeccc,
  "highlight": #ff7f00,
  "highlight-light": #ffcc99,
);

$body-bg: #f0f0f0 !default;

// forms
$input-border-color: #5c7098 !default;
$input-bg:           #c3d7ff !default;
$input-disabled-bg:  #b8bcc0 !default;

// font awesome path
$fa-font-path : '~@fortawesome/fontawesome-free/webfonts';
