
// Fix wrong tooltip positioning. Only necessary if boundary is other than 'scrollParent'.
// See: https://github.com/bootstrap-vue/bootstrap-vue/issues/1732
.tooltip {
  top: 0;
}

.no-outline {
  outline: 0 !important;
}

.editor-container {
  height: 100%;
  overflow: auto;
  padding-top: 40px;
  position: relative;
}
.editor-toolbar {
  height: 40px;
  margin-top: -40px;
}
.ql-editor {
  font-size: 12px;
  font-family: Arial, sans-serif;
}

.ql-snow {
  /* Change the width so that the font names fit on one line. */
  .ql-picker.ql-font {
    width: 160px;
  }

  .ql-picker.ql-size {
    width: 60px;
  }

  /* Scrollable font and size list */
  .ql-font.ql-picker.ql-expanded .ql-picker-options,
  .ql-size.ql-picker.ql-expanded .ql-picker-options {
    overflow-y: scroll;
    height: 140px;
  }

  .ql-picker-item[data-label="Arial"]::before,
  .ql-picker-label[data-label="Arial"]::before {
    font-family: Arial, Helvetica, sans-serif;
  }

  .ql-picker-item[data-label="Comic Sans MS"]::before,
  .ql-picker-label[data-label="Comic Sans MS"]::before {
    font-family: "Comic Sans MS", ChalkboardSE-Regular, cursive, sans-serif;
  }

  .ql-picker-item[data-label="Courier New"]::before,
  .ql-picker-label[data-label="Courier New"]::before {
    font-family: "Courier New", Courier, monospace;
  }

  .ql-picker-item[data-label="Impact"]::before,
  .ql-picker-label[data-label="Impact"]::before {
    font-family: Impact, DINCondensed-Bold, Charcoal, sans-serif;
  }

  .ql-picker-item[data-label="Lucida Grande"]::before,
  .ql-picker-label[data-label="Lucida Grande"]::before {
    font-family: "Lucida Grande", "Lucida Sans Unicode", sans-serif;
  }

  .ql-picker-item[data-label="Palatino"]::before,
  .ql-picker-label[data-label="Palatino"]::before {
    font-family: Palatino, "Palatino Linotype", "Book Antiqua", serif;
  }

  .ql-picker-item[data-label="Tahoma"]::before,
  .ql-picker-label[data-label="Tahoma"]::before {
    font-family: Tahoma, Helvetica, Geneva, sans-serif;
  }

  .ql-picker-item[data-label="Times New Roman"]::before,
  .ql-picker-label[data-label="Times New Roman"]::before {
    font-family: "Times New Roman", Times, serif;
  }

  .ql-picker-item[data-label="Trebuchet MS"]::before,
  .ql-picker-label[data-label="Trebuchet MS"]::before {
    font-family: "Trebuchet MS", Helvetica, sans-serif;
  }

  .ql-picker-item[data-label="Verdana"]::before,
  .ql-picker-label[data-label="Verdana"]::before {
    font-family: Verdana, Geneva, sans-serif;
  }
}
