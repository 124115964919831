
@import "../../../custom";

.drag-area {
  background-color: $body-bg;
  border: 2px solid $body-bg;
  min-height: 30px;
}
.drop-target {
  border-width: 2px !important;
}
.sortable-ghost {
  opacity: 0.3;
}
