
@import "../../../_custom.scss";

.report-line {
  transition: background-color 0.2s linear, border-left 0.2s linear;

  &:not(:last-child) {
    border-bottom: 1px dashed map-get($theme-colors, "light") !important;
  }

  &:hover {
    background-color: map-get($theme-colors, "hover") !important;
  }
}
