
@import "../../../custom";

.cursor-move {
  cursor: move !important;
}

.placeholder {
  border: 0;
  background-color: $input-bg;
}
