
@import "../../../_custom.scss";

.dropdown-item-check-wrapper {
  min-width: 32px;
  justify-content: center;
  align-items: center;
  color: map-get($theme-colors, "primary");
}

.dropdown-item-icon-wrapper {
  min-width: 32px;
  justify-content: center;
  align-items: center;
}
.dropdown-selectable-item {
  padding: 0;
}
.dropdown-item-content {
  padding: 4px 8px 4px 0;
}

div.vc-highlights:has(div.vc-highlight-base-end) {
  width: 10px;
}

.filter-group {
  background-color: $input-bg;
}
.range {
  border-color: $input-bg !important;
  border-radius: 100%;
}

.vc-reset {
  border-color: inherit !important;
  border-width: 1px !important;
}

.dropdown-filter {
  background-color: $input-bg !important;
}
.overlay-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.vc-bg-blue-600 {
  background-color: map-get($theme-colors, "primary") !important;
}
.vc-bg-blue-200 {
  background-color: $input-bg !important;
}

/* Conditional CSS for IE */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vc-popover-content-wrapper {
    top: 10px !important;
  }
  .vc-popover-content {
    padding-top: 10px;
  }
}
/* disable highligting of empty days from other month */
.vc-opacity-0 .vc-highlight {
  border-color: transparent !important;
}
/* Conditional CSS for IE https://github.com/nathanreyes/v-calendar/issues/303 */
.vc-container {
  .vc-grid-container {
    width: 100%;
    display: -ms-grid;
    &.vc-weeks {
      padding: 5px 6px 7px 6px;
    }
    .vc-day-content {
      min-height: 1.8rem;
      width: 2.1rem;
      height: 1.8rem;
      margin: 0.1rem auto;
    }
    .vc-highlight {
      height: 1.8rem;
      width: 1.8rem;
    }
  }
}
